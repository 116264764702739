import React from "react";
import Carousel from './Carousel/Carousel'
export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Виды сертификатов</h2>          
        </div>
        <Carousel value={props.data}/>
      </div>
    </div>
  );
};
