import React from "react";
import svg from "../assets/404.svg"
import { useNavigate } from "react-router-dom";

function PageNotFound(){
    let navigate = useNavigate(); 
    const BackHome =()=>{
        navigate("/");
    }
    const cont404 ={
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
    }
    const img404 ={
        height: "70vh",
        width: "50vw"
    }
    const button404={
        padding: "12px 15px",
        backgroundColor: "#9253ff",
        border: "none",
        borderRadius: "12px",
        cursor: "pointer",
        color: "#fff",
        fontSize: "medium",
        fontWeight: "bold"
    }
    return (
        <>
        <style>                        
            .cont-404 h1{
                
            }
        </style>
        <div className="cont-404" style={cont404}>
            <img src={svg} alt="svg" style={img404}/>
            <button type="submit" onClick={BackHome} style={button404}>Back to Home</button>
        </div>
        </>
        
    )
}

export default PageNotFound