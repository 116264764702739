import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectCoverflow, Autoplay, Zoom, Navigation } from 'swiper'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import './Carousel.css'
export default (props)=>{
    const isSmall = props.small;
    const modules = [Pagination, EffectCoverflow, Autoplay, Navigation];
    const smallModules = [Pagination,  Autoplay, Navigation];
  return (
    <div className='carousel'>       

        <Swiper 
        className='myswiper'
        
        modules={isSmall? smallModules:modules}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 3,
            slideShadows: true
        }}
        loop={true}
        zoom={true}
        observer= {true}
        observeParents={true}
        pagination={{clickable: true}}
        navigation={true}
        

        autoplay={{
            delay: 5000,
            disableOnInteraction: false
        }}
        
        breakpoints={{
            640: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 1
            },
            1024: {
                slidesPerView: 2
            },
            1560: {
                slidesPerView: 3
            },
        }}        
        >
            {props.value?
                props.value.map(data => (
                    <SwiperSlide style={{ backgroundImage: `url(${data.img})` }} className={data.type === "partner"?"myswiper-slider-partner":"myswiper-slider"}>
                        <div>
                            <h2>{data.title}</h2>
                            <p>{data.description}</p>                            
                        </div>
                    </SwiperSlide>
                )):"Loading..."
            }
        </Swiper>

    </div>
  )
}